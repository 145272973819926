<template>
  <div v-if="!item.hidden">
    <template
      v-if="
        hasOneShowingChild(item.children, item) &&
        (!onlyOneChild.children || onlyOneChild.noShowingChildren) &&
        !item.alwaysShow
      "
    >
      <app-link v-if="onlyOneChild.meta" :to="resolvePath(onlyOneChild.path)">
        <div v-if="item.meta?.showDivideLine" class="divide-line"></div>
        <vh-menu-item
          :index="resolvePath(onlyOneChild.path)"
          :class="{ 'submenu-title-noDropdown': !isNest }"
        >
          <!-- <template slot="title"> -->
          <!--
            <icon class="sidebar-font" :icon-class="onlyOneChild.meta.icon||(item.meta&&item.meta.icon)" v-if="onlyOneChild.meta.icon||(item.meta&&item.meta.icon)"></icon>
            -->
          <i
            :class="`sidebar-font ${onlyOneChild.meta.icon || (item.meta && item.meta.icon)}`"
            v-if="onlyOneChild.meta.icon || (item.meta && item.meta.icon)"
          />
          <span slot="title" v-if="onlyOneChild.meta.title">{{ onlyOneChild.meta.title }}</span>
          <!-- </template> -->
        </vh-menu-item>
      </app-link>
    </template>

    <vh-submenu v-else ref="subMenu" :index="resolvePath(item.path)" popper-append-to-body>
      <!-- 包含子菜单 -->
      <template slot="title">
        <!--
          <icon class="sidebar-font" :icon-class="item.meta && item.meta.icon" v-if="item.meta && item.meta.icon"></icon>
        -->
        <i
          :class="`sidebar-font ${item.meta && item.meta.icon}`"
          v-if="item.meta && item.meta.icon"
        ></i>
        <span slot="title" v-if="item.meta.title">{{ item.meta.title }}</span>
      </template>
      <sidebar-item
        v-for="child in newChild"
        :key="child.path"
        :is-nest="true"
        :item="child"
        :base-path="resolvePath(child.path)"
        class="nest-menu"
      />
    </vh-submenu>
  </div>
</template>

<script>
  import path from 'path';
  import AppLink from './Link';
  import { sessionOrLocal } from '../../../utils/utils';
  import regRule from '@/utils/reg-rule.js';

  export default {
    name: 'SidebarItem',
    components: { AppLink },
    props: {
      // route object
      item: {
        type: Object,
        required: true
      },
      isNest: {
        type: Boolean,
        default: false
      },
      basePath: {
        type: String,
        default: ''
      },
      // 权限
      childPremission: {
        required: false,
        type: Object,
        default: () => {}
      }
    },
    computed: {
      newChild: function () {
        let children = this.item.children;
        // console.log('children', children);
        let userInfo = sessionOrLocal.get('userInfo');
        let permissions = sessionOrLocal.get('SAAS_VS_PES', 'localStorage');
        let perVo = permissions ? JSON.parse(permissions) : {};
        children = children.filter(items => {
          return (
            items.meta.name !== 'brandMgr' ||
            (items.meta.name === 'brandMgr' &&
              !(
                perVo['ui.brand_setting'] == 0 &&
                perVo['webinar_skins'] == 0 &&
                perVo['watch.viewing_protocol'] == 0
              ))
          );
        });
        if (userInfo) {
          let vo = JSON.parse(userInfo);
          if (vo.parent_id > 0) {
            // 是子账号情况下，若路由不是子账号管理，展示
            return children
              .filter(
                item =>
                  this.permission_fin || (!this.permission_fin && item.meta.name !== 'financeInfo')
              )
              .filter(item => item.meta.name !== 'sonMgr')
              .filter(item => {
                // 子账号是否展示 运营设置→控制台标识，需要判断权限(子账号admin中的ui.console_logo不控制)
                return (
                  item.meta.name !== 'logoMgr' ||
                  (item.meta.name === 'logoMgr' &&
                    this.childPremission?.['permission_setting_logo'] == 1)
                );
              })
              .filter(item => {
                if (item.meta.auth_key) {
                  return item.meta.auth_key && perVo && perVo[item.meta.auth_key] > 0;
                } else {
                  return item;
                }
              });
          } else {
            // let permissions = sessionOrLocal.get('SAAS_VS_PES', 'localStorage');
            // let perVo = permissions ? JSON.parse(permissions) : {};
            // TODO 模拟 perVo['child_num_limit'] = 0;
            if (perVo && Number(perVo['child_num_limit']) !== 1) {
              // 父账号，但是没有子账号管理
              return children.filter(item => {
                if (item.meta.auth_key) {
                  // 配置了该项，表示按照此权限处理；未配置该项，正常处理
                  return (
                    !(item.meta.name === 'sonMgr') &&
                    item.meta.auth_key &&
                    perVo &&
                    perVo[item.meta.auth_key] > 0
                  );
                } else {
                  return !(item.meta.name === 'sonMgr');
                }
              });
            } else {
              return children.filter(item => {
                if (item.meta.auth_key) {
                  return item.meta.auth_key && perVo && perVo[item.meta.auth_key] > 0;
                } else {
                  return item;
                }
              });
            }
          }
        } else {
          return children;
        }
      },
      //财务管理
      permission_fin() {
        return !(this.childPremission && Number(this.childPremission.permission_fin) === 0);
      }
    },
    data() {
      return {
        onlyOneChild: false
      };
    },
    methods: {
      // 判断是否只有一级导航
      hasOneShowingChild(children = [], parent) {
        // 一级导航循环
        const showingChildren = children.filter(item => {
          if (item.hidden) {
            return false;
          } else {
            this.onlyOneChild = item;
            return true;
          }
        });
        if (showingChildren.length === 1) {
          if (showingChildren[0]?.meta?.showOneChildren) {
            return false;
          }
          return true;
        }
        if (showingChildren.length === 0) {
          this.onlyOneChild = { ...parent, path: '', noShowingChildren: true };
          return true;
        }
        return false;
      },
      resolvePath(routePath) {
        if (regRule.isExternal.test(routePath)) {
          return routePath;
        }
        if (regRule.isExternal.test(this.basePath)) {
          return this.basePath;
        }
        return path.resolve(this.basePath, routePath);
      }
    }
  };
</script>
<style scoped lang="less">
  .divide-line {
    border-bottom: 1px solid #ddd;
    height: 1px;
    width: 100%;
    margin: 1px 0;
  }
</style>
