<template>
  <div>
    <section>
      <p class="subject">准备</p>
      <div class="subjectOuter">
        <template v-for="item in readyList">
          <div class="sunjectInner" @click="blockHandler(item)" :key="item.id" v-if="item.isShow">
            <span><img :src="require(`../../common/images/icon/${item.icon}.png`)" alt="" /></span>
            <div class="desc">
              <p class="mainText">{{ item.title }}</p>
              <p class="subText">{{ item.subText }}</p>
            </div>
          </div>
        </template>
      </div>
    </section>
    <section v-if="isInvite">
      <p class="subject">邀约</p>
      <div class="subjectOuter">
        <template v-for="item in inviteList">
          <div class="sunjectInner" :key="item.id" @click="blockHandler(item)" v-if="item.isShow">
            <!-- <icon class="icon" :icon-class="item.icon"></icon> -->
            <span><img :src="require(`../../common/images/icon/${item.icon}.png`)" alt="" /></span>
            <div class="desc">
              <p class="mainText">{{ item.title }}</p>
              <p class="subText">{{ item.subText }}</p>
            </div>
          </div>
        </template>
      </div>
    </section>
    <section v-if="isBrand">
      <p class="subject">品牌</p>
      <div class="subjectOuter">
        <template v-for="item in brandList">
          <div class="sunjectInner" :key="item.id" @click="blockHandler(item)" v-if="item.isShow">
            <!-- <icon class="icon" :icon-class="item.icon"></icon> -->
            <span><img :src="require(`../../common/images/icon/${item.icon}.png`)" alt="" /></span>
            <div class="desc">
              <p class="mainText">{{ item.title }}</p>
              <p class="subText">{{ item.subText }}</p>
            </div>
          </div>
        </template>
      </div>
    </section>
    <section v-if="isIntact">
      <p class="subject">互动</p>
      <div class="subjectOuter">
        <template v-for="item in liveDataList">
          <div class="sunjectInner" :key="item.id" @click="blockHandler(item)" v-if="item.isShow">
            <span><img :src="require(`../../common/images/icon/${item.icon}.png`)" alt="" /></span>
            <div class="name">{{ item.name }}</div>
            <div class="desc">
              <p class="mainText">{{ item.title }}</p>
              <p class="subText">{{ item.subText }}</p>
            </div>
          </div>
        </template>
      </div>
    </section>
    <section v-if="!(childPremission && Number(childPremission.permission_content) === 0)">
      <p class="subject">
        {{ webinarType == 5 ? '视频与AI创作' : type == 4 ? '点播与AI创作' : '回放与AI创作' }}
      </p>
      <div class="subjectOuter">
        <div class="sunjectInner" @click="goHandler">
          <span>
            <img src="../../common/images/icon/icon_timing@2x.png" alt="" v-if="webinarType == 5" />
            <img src="../../common/images/icon/icon_review.png" alt="" v-else />
          </span>
          <div class="desc">
            <p class="mainText">
              {{
                webinarType == 5
                  ? '视频管理与AI创作'
                  : type == 4
                  ? '点播管理与AI创作'
                  : '回放管理与AI创作'
              }}
            </p>
            <p class="subText">
              {{
                webinarType == 5
                  ? '管理视频内容，进行AI创作'
                  : type == 4
                  ? '管理点播内容，进行AI创作'
                  : '管理直播回放内容，进行AI创作'
              }}
            </p>
          </div>
        </div>
      </div>
    </section>
    <section v-if="!(childPremission && Number(childPremission.permission_data) === 0)">
      <p class="subject">数据</p>
      <div class="subjectOuter">
        <template v-for="item in reportDataList">
          <div
            class="sunjectInner"
            @click="blockDataHandler(item)"
            :key="item.id"
            v-if="item.isShow"
          >
            <!-- <icon class="icon" :icon-class="item.icon"></icon> -->
            <span><img :src="require(`../../common/images/icon/${item.icon}.png`)" alt="" /></span>
            <div class="desc">
              <p class="mainText">{{ item.title }}</p>
              <p class="subText">{{ item.subText }}</p>
            </div>
          </div>
        </template>
        <div
          v-for="(item, idx) in dataMenuExtensions"
          class="sunjectInner"
          @click="clickExtensionMenu('data', item, idx)"
          :key="item.url"
        >
          <!-- <icon class="icon" :icon-class="item.icon"></icon> -->
          <span>
            <img :src="item.icon || require(`../../common/images/icon/icon_more.png`)" alt="" />
          </span>
          <div class="desc">
            <p class="mainText">{{ item.title }}</p>
            <p class="subText">{{ item.desc }}</p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  import { sessionOrLocal } from '@/utils/utils';
  import { isEmbed } from '../../utils/utils';

  export default {
    name: 'index.vue',
    props: {
      operas: {
        type: Object
      },
      type: {
        type: Number,
        default: 1
      },
      perssionInfo: {
        type: Object
      },
      webinarType: {
        type: Number,
        default: 2
      },
      childPremission: {
        type: Object
      },
      videoType: {
        type: Boolean,
        default: false
      }
    },
    async created() {
      await this.getThirdEmbedPages();
      this.resetList(this.perssionInfo);
    },
    watch: {
      perssionInfo: {
        handler(val) {
          if (val) {
            this.resetList(val);
          }
        },
        immediate: true,
        deep: true
      }
    },
    data() {
      return {
        loading: false,
        readyList: [],
        brandList: [],
        inviteList: [],
        liveDataList: [],
        reportDataList: [],
        dataMenuExtensions: []
      };
    },
    computed: {
      isBrand() {
        let isbrand;
        isbrand = this.brandList.some(item => {
          // eslint-disable-next-line no-prototype-builtins
          return item.isShow == true;
        });
        return isbrand;
      },
      isInvite() {
        let isInvite;
        isInvite = this.inviteList.some(item => {
          // eslint-disable-next-line no-prototype-builtins
          return item.isShow == true;
        });
        return isInvite;
      },
      isIntact() {
        let isIntact;
        isIntact = this.liveDataList.some(item => {
          // eslint-disable-next-line no-prototype-builtins
          return item.isShow == true;
        });
        return isIntact;
      }
    },
    methods: {
      getLanguagePermission() {
        return this.$fetch('getConfigList', {
          webinar_id: this.$route.params.str,
          webinar_user_id: '',
          send_id: 1
        })
          .then(res => {
            if (res.data && res.data.permissions) {
              const conf = JSON.parse(res.data.permissions);
              const langPermission = conf.multilingual || 0;
              const webinarType = sessionOrLocal.get('webinarType');
              if (langPermission == 1 && webinarType && webinarType != 6) {
                this.$nextTick(() => {
                  this.readyList.push({
                    icon: 'icon_languages@2x',
                    id: 9,
                    title: '多语言链接',
                    subText: `获取多语言观看链接及二维码`,
                    type: 100066,
                    path: `/live/langCard/${this.$route.params.str}`,
                    isShow: true
                  });
                });
              }
            }
          })
          .catch(e => {
            console.log('demo>>>>>获取多语言配置success4:', e);
          });
      },
      resetList(perssionInfo) {
        console.log(perssionInfo);
        const userInfo = JSON.parse(sessionOrLocal.get('userInfo'));
        const embed = isEmbed() ? '/embed' : '';
        (this.readyList = [
          {
            icon: 'icon_information@2x',
            id: 1,
            title: '基本信息',
            subText: `编辑${this.type == 4 ? '点播' : '直播'}基本信息`,
            path: `${embed}/live/edit`,
            isShow: true,
            type: 100058
          },
          {
            icon: 'icon_Functional@2x',
            id: 2,
            title: '功能配置',
            subText: `设置观看页功能是否展示`,
            type: 100059,
            path: `${embed}/live/planFunction/${this.$route.params.str}`,
            isShow: true
          },
          {
            icon: 'icon_watch@2x',
            id: 3,
            title: '观看限制',
            subText: `设置${this.type == 4 ? '点播' : '直播'}观看限制`,
            type: 100060,
            path: `${embed}/live/viewerRules/${this.$route.params.str}`,
            isShow: true
          },
          {
            icon: 'icon_role@2x',
            id: 4,
            title: '角色权限',
            subText: '设置不同角色参与直播的权限',
            index: 4,
            type: 100061,
            path: `${embed}/live/roleInvitation/${this.$route.params.str}`,
            isShow: this.type != 4
          },
          {
            icon: 'icon_video@2x',
            id: 5,
            title: '暖场视频',
            subText: '开启后设置暖场视频',
            index: 4,
            type: 100062,
            path: `${embed}/live/warm/${this.$route.params.str}`,
            isShow: this.type != 4 && this.webinarType != 1 && !this.videoType
          },
          {
            icon: 'icon_virtual@2x',
            id: 6,
            title: '虚拟人数',
            subText: `添加${this.type == 4 ? '点播' : '直播'}和点赞虚拟人数`,
            type: 100063,
            path: `${embed}/live/virtual/${this.$route.params.str}`,
            isShow: this.webinarType == 6 || perssionInfo.virtual_user == 1
          },
          {
            icon: 'icon_registration_form@2x',
            id: 7,
            title: '报名表单',
            subText: '开启后收集目标观众信息',
            type: 100064,
            path: `/live/signup/${this.$route.params.str}`,
            isShow: !embed && perssionInfo.join_check == 1
          },
          {
            icon: 'icon_embedded@2x',
            id: 8,
            title: '嵌入方式',
            subText: `获取活动iframe嵌入方式的方法`,
            type: 100065,
            path: `${embed}/live/embedCard/${this.$route.params.str}`,
            isShow: true
          },
          {
            icon: 'icon_languages@2x',
            id: 9,
            title: '多语言链接',
            subText: `获取多语言观看链接及二维码`,
            type: 100845,
            path: `/live/langCard/${this.$route.params.str}`,
            isShow: perssionInfo.multilingual == 1 && this.webinarType != 6 && !embed
          },
          {
            icon: 'icon_compliance@2x',
            id: 10,
            title: '合规风险设置',
            subText: `对进入直播间的主播/观众进行风险提示，确认后方可进入到直播间`,
            type: 100846,
            path: `${embed}/live/compliance/${this.$route.params.str}`,
            isShow: !embed
          }
        ]),
          (this.inviteList = [
            {
              icon: 'icon_channel',
              id: 1,
              title: '推广渠道',
              subText: `自建传播渠道，实现多渠道推广`,
              type: null, // TODO 需要上报KEY
              path: `/live/channel/${this.$route.params.str}`,
              isShow: !embed && perssionInfo['watch.channel'] == 1
            },
            {
              icon: 'icon_invitation@2x',
              id: 2,
              title: '邀约海报',
              subText: `用于${this.type == 4 ? '点播' : '直播'}邀请或裂变分享`,
              type: 100069,
              path: `/live/invCard/${this.$route.params.str}`,
              isShow: !embed && perssionInfo.btn_invite == 1
            },
            {
              icon: 'icon_notice@2x',
              id: 3,
              title: '开播提醒',
              subText: '通过短信或微信方式向目标观众发送提醒通知',
              type: null, // TODO 需要上报KEY
              path: `/live/msgNotification/${this.$route.params.str}`,
              isShow:
                !embed &&
                perssionInfo.message_notice == 1 &&
                this.type != 4 &&
                userInfo.user_extends.extends_remark != 1 // 不是知学云账号 & 开启了 短信通知配置项权限
            },
            {
              icon: 'icon_shareSetting@2x',
              id: 4,
              title: '微信分享',
              subText: '设置活动分享到微信中的效果',
              type: 100846,
              path: `/live/shareSetting/${this.$route.params.str}`,
              isShow: !embed && perssionInfo.share_set == 1
            }
          ]),
          (this.brandList = [
            {
              icon: 'icon_brand@2x',
              id: 1,
              title: '直播间设计器',
              subText: '设置直播间的主题、布局和自定义菜单',
              type: 100066,
              path: `${embed}/live/livingSet/${this.$route.params.str}`,
              isShow: true
            },
            // {
            //   icon: 'icon_brand@2x',
            //   id: 1,
            //   title: '品牌设置',
            //   subText: '设置观看页品牌信息',
            //   type: 100066,
            //   path: `/live/brandSet/${this.$route.params.str}`,
            //   isShow:
            //     perssionInfo['ui.brand_setting'] > 0 ||
            //     perssionInfo.webinar_skins > 0 ||
            //     perssionInfo['watch.viewing_protocol'] > 0,
            // },
            // {
            //   icon: 'icon_custom@2x',
            //   id: 2,
            //   title: '自定义菜单',
            //   subText: '自定义观看页菜单栏',
            //   type: 100067,
            //   path: `/live/customTab/${this.$route.params.str}`,
            // },
            {
              icon: 'color-recording-screen@2x',
              id: 9,
              title: '防录屏设置',
              subText: `设置${this.type == 4 ? '点播' : '直播'}跑马灯水印`,
              type: 100068,
              path: `${embed}/live/safeScreenSet/${this.$route.params.str}`,
              isShow: perssionInfo.player_config == 1
            },
            {
              icon: 'icon_player@2x',
              id: 3,
              title: '播放器设置',
              subText: `设置播放器画面显示功能`,
              type: 100068,
              path: `${embed}/live/playerSet/${this.$route.params.str}`,
              isShow: perssionInfo.player_config == 1
            },
            {
              icon: 'icon_advertising@2x',
              id: 5,
              title: '广告',
              subText: '设置观看页广告位信息',
              type: 100070,
              path: `/live/advertCard/${this.$route.params.str}`,
              isShow: !embed && perssionInfo.ad_recommend == 1
            },
            {
              icon: 'icon_public@2x',
              id: 6,
              title: '公众号',
              subText: '设置观看页展示的公众号',
              type: 100071,
              path: `/live/officeSet/${this.$route.params.str}`,
              isShow:
                this.webinarType == 6 || embed ? false : perssionInfo.live_broadcast_window == 1
            },
            {
              icon: 'icon_posters@2x',
              id: 7,
              title: '开屏海报',
              subText: '设置观看页的开屏海报',
              type: 100072,
              path: `/live/posterCard/${this.$route.params.str}`,
              isShow: !embed && perssionInfo.market_open_posters == 1
            }
          ]),
          (this.liveDataList = [
            {
              icon: 'icon_document@2x',
              id: 1,
              title: '文档',
              subText: '直播中使用文档演示',
              type: 100073,
              path: `${embed}/live/word/${this.$route.params.str}`,
              isShow: true
            },
            {
              icon: 'icon_videoSet@2x',
              id: 6,
              title: '插播文件',
              subText: '直播中使用音视频文件演示',
              type: 100847,
              path: `${embed}/live/videoSet/${this.$route.params.str}`,
              isShow:
                this.webinarType != 5 && perssionInfo['waiting.video.file'] == 1 && this.type != 4
            },
            {
              icon: 'icon_Lucky_draw@2x',
              id: 2,
              title: '在线抽奖',
              subText: '直播中发起抽奖活跃气氛',
              type: 100074,
              path: `${embed}/live/prizeList/${this.$route.params.str}`,
              isShow: perssionInfo['ui.hide_lottery'] == 1 && this.type != 4
            },
            {
              icon: 'icon_questionnaire@2x',
              id: 3,
              title: '问卷',
              subText: '创建问卷收集信息',
              type: 100075,
              path: `${embed}/live/question`,
              isShow: perssionInfo['ui.hide_survey'] == 1 // 没权限不支持
            },
            {
              icon: 'icon_exam',
              id: 7,
              title: '快问快答',
              subText: '提升答题趣味性，强化知识掌握',
              type: 100075,
              path: `${embed}/live/exam`,
              isShow: perssionInfo['exam'] == 1 && this.type != 4 // 点播直播不支持；没权限也不支持
            },
            {
              icon: 'icon_card',
              id: 8,
              title: '推屏卡片',
              subText: '直播中推送图文卡片引流',
              type: 100075,
              path: `${embed}/live/pushCard`,
              isShow: perssionInfo['push_screen_card'] == 1 && this.type != 4 // 点播直播不支持；没权限也不支持
            },
            {
              icon: 'icon_goods@2x',
              id: 4,
              title: '商品与优惠券',
              subText: '观看可下单购买商品，可领取优惠券',
              type: 100076,
              path: `${embed}/live/productSet/${this.$route.params.str}`,
              isShow: perssionInfo.product_show == 1
            },
            {
              icon: 'icon_gift@2x',
              id: 5,
              title: '礼物',
              subText: '设置观众发送的礼物信息',
              type: 100077,
              path: `${embed}/live/gift/${this.$route.params.str}`,
              isShow: perssionInfo['ui.show_gift'] == 1
            },
            {
              icon: 'icon-interact',
              id: 9,
              title: '互动有礼',
              subText: '完成任务后可获得礼品',
              type: 100078,
              path: `${embed}/live/InteractReward/${this.$route.params.str}`,
              isShow: perssionInfo['ui.interact_reward'] == 1 && this.type != 4
            },
            {
              icon: 'icon-watch-reward',
              id: 11,
              title: '观看有礼',
              subText: '设置福袋奖励，提升观看时长',
              type: 100079,
              path: `${embed}/live/WatchReward/${this.$route.params.str}`,
              isShow: perssionInfo['watch_gift'] == 1 && this.type != 4 && !embed
            },
            {
              icon: 'icon-AI',
              id: 10,
              title: 'AI聊天',
              subText: '设置聊天机器人活跃直播间氛围',
              type: 100078,
              path: `${embed}/live/AI/chat/list/${this.$route.params.str}`,
              isShow: perssionInfo['ai_chat'] == 1 && this.type != 4
            }
          ]),
          (this.reportDataList = [
            {
              icon: 'icon_data@2x',
              id: 1,
              title: '数据报告',
              subText: `统计${this.type == 4 ? '点播' : '直播'}基本数据`,
              type: 100042,
              path: `${embed}/live/reportsData/${this.$route.params.str}`,
              isShow: true
            },
            {
              icon: 'icon_interactive@2x',
              id: 2,
              title: '互动统计',
              subText: `统计${this.type == 4 ? '点播' : '直播'}互动工具数据`,
              type: 100043,
              path: `${embed}/live/interactionData/${this.$route.params.str}`,
              isShow: true
            },
            {
              icon: 'icon_User statistics@2x',
              id: 3,
              title: '用户统计',
              subText: `统计${this.type == 4 ? '点播' : '直播'}观众详细数据`,
              type: 100044,
              path: `${embed}/live/userData/${this.$route.params.str}`,
              isShow: true
            }
          ]);
      },
      blockHandler(item) {
        let userId = this.$parent.userId;
        this.$vhall_paas_port({
          k: item.type,
          data: {
            business_uid: userId,
            user_id: '',
            webinar_id: this.$route.params.str,
            refer: '',
            s: '',
            report_extra: {},
            ref_url: '',
            req_url: ''
          }
        });
        this.$emit('blockHandler', item);
      },
      blockDataHandler(item) {
        let userId = this.$parent.userId;
        this.$vhall_paas_port({
          k: item.type,
          data: {
            business_uid: userId,
            user_id: '',
            webinar_id: this.$route.params.str,
            refer: 2,
            s: '',
            report_extra: {},
            ref_url: '',
            req_url: ''
          }
        });
        this.$emit('blockHandler', item);
      },
      goHandler() {
        const path =
          this.webinarType == 5
            ? `${isEmbed() ? '/embed' : ''}/live/publishplayback/${this.$route.params.str}`
            : this.type == 4
            ? `${isEmbed() ? '/embed' : ''}/live/recordplayback/${this.$route.params.str}`
            : `${isEmbed() ? '/embed' : ''}/live/playback/${this.$route.params.str}`;
        let userId = this.$parent.userId;
        this.$vhall_paas_port({
          k: this.type === 4 ? 100079 : 100078,
          data: {
            business_uid: userId,
            user_id: '',
            webinar_id: this.$route.params.str,
            refer: '',
            s: '',
            report_extra: {},
            ref_url: '',
            req_url: ''
          }
        });
        this.$router.push({ path: path, query: { type: this.webinarType, state: this.type } });
      },
      async getThirdEmbedPages() {
        let params = {
          webinar_id: this.$route.params.str
        };
        console.log('当前参数传递：', params);
        try {
          const res = await this.$fetch('getThirdEmbedPages', params);
          if (res?.code === 200 && res?.data) {
            this.dataMenuExtensions = res?.data['data.third_embed_page'] || [];
            localStorage.setItem(
              'VH_DEP_CONSOLE_THIRD_EMBED_PAGE_CONFIG',
              JSON.stringify(res?.data)
            );
          } else {
            localStorage.remove('VH_DEP_CONSOLE_THIRD_EMBED_PAGE_CONFIG');
          }
        } catch (e) {
          localStorage.remove('VH_DEP_CONSOLE_THIRD_EMBED_PAGE_CONFIG');
          console.error(e);
        }
      },
      clickExtensionMenu(type, item, index) {
        this.$router.push({
          path: `/live/embedPage/${this.$route.params.str}/${type}/${index}`
        });
      }
    }
  };
</script>

<style lang="less" scoped>
  .subject {
    font-size: 20px;
    color: #333333;
    border-left: 4px solid #fb3a32;
    line-height: 16px;
    height: 18px;
    margin-top: 32px;
    margin-bottom: 12px;
    padding-left: 5px;
  }
  .subjectOuter {
    display: flex;
    flex-wrap: wrap;
    margin-left: -18px;
    // justify-content: space-between;
  }
  .sunjectInner {
    display: flex;
    background: #fff;
    width: 330px;
    height: 110px;
    align-items: center;
    margin-bottom: 24px;
    margin-left: 17px;
    box-sizing: border-box;
    background-clip: content-box;
    cursor: pointer;
    border-radius: 4px;
    border: 1px solid transparent;
    transition: all 0.15s ease-in;
    &:hover {
      box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.08), 0px 2px 4px 0px rgba(0, 0, 0, 0.02);
      border: 1px solid #fb3a32;
    }
    span {
      width: 44px;
      height: 44px;
      display: inline-block;
      margin-right: 15px;
      border-radius: 50%;
      margin-left: 35px;
      img {
        width: 100%;
        height: 100%;
        // background: #FB3A32;
        // border: 1px solid #ccc;
      }
    }
    /deep/.svg-icon {
      text-align: center;
      font-size: 50px;
      vertical-align: top;
      color: #fb3a32;
    }
    .desc {
      .mainText {
        font-size: 18px;
        color: #1a1a1a;
        margin-bottom: 8px;
      }
      .subText {
        font-size: 14px;
        color: #666666;
        max-width: 220px;
        line-height: 20px;
      }
    }
  }
</style>
